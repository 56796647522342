@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  font-family: "Open Sans";
}

html,
body {
  background-color: #000000 !important;
}

#overlay.enabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  pointer-events: all;
}

.error {
  color: #aa0000;
}

.error-message {
  color: #aa0000;
  margin-top: 30px;
  line-height: 20px;
}

main {
  color: #ffffff;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.15, 1.15);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0.5;
  }
}

#pulsing-logo img {
  height: 140px;
  width: auto;
  transition: all 1s;
  animation: pulse 2s infinite;
}

#typewriter-introduction {
  width: 100%;
}

#typewriter-final-score {
  width: 50%;
}

.typewriter span {
  opacity: 0;
  transition: opacity 1s;
  border-right: solid 3px transparent;
}

.typewriter.started span {
  opacity: 1;
}

.animated {
  opacity: 0;
  width: 100%;
  position: absolute;
  transform: scale(1);
  text-shadow: none;
  overflow: hidden;
}

#question {
  opacity: 0;  
}

#question .selected {
  background-color: #aa0000;
  border: solid 4px #ffffff !important;
  color: #ffffff;
}

#question .selected.correct {
  background-color: #00aa00;
  border: solid 4px #ffffff;
  color: #ffffff;
}

.square_lit {
  background-color: #5594f1;
}

/* #score {
  text-align: left;
  font-size: 30px;
  display: inline-block;
} */


/* custom hight  */
.custom_hight {
  min-height: 90vh;
}